import "./App.css";
import Row from "./components/row/Row";
import requests from "./requests";
import Banner from "./components/banner/Banner";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Banner />
      <Row
        title="Netflix Originals"
        URL={requests.fetchNetflixOriginals}
        isLargeRow={true}
      />
      <Row title="Trending Now" URL={requests.fetchTrending} />
      <Row title="Top Rated" URL={requests.fetchTopRated} />
      <Row title="Action" URL={requests.fetchActionMovies} />
      <Row title="Comedy" URL={requests.fetchComedyMovies} />
      <Row title="Documentaries" URL={requests.fetchDocumantaries} />
      <Row title="Romance" URL={requests.fetchRomanceMovies} />
      <Row title="Horror" URL={requests.fetchHorrorMovies} />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
